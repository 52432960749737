/*===========================================
	VARIAVEIS
============================================*/

@import 'variaveis';

/*===========================================
	BASE
============================================*/

@import 'base';

/*===========================================
	HEADER
============================================*/

@import 'header';
@import 'header2';

/*===========================================
	CONTENT
============================================*/

@import 'content';

/*===========================================
	ESTOQUE
============================================*/

@import 'estoque';

/*===========================================
	MOBILE MENU
============================================*/

@import 'menu';


/*===========================================
	FOOTER
============================================*/

@import 'footer';


/*===========================================
	BOOTSTRAP
============================================*/

@import 'bootstrap';

@import 'vendor/alertify.core';
@import 'vendor/alertify.default';

/*===========================================
	RESPONSIVO
============================================*/

@import 'mobile';



// Horario de Funcionamento

.horario-de-funcionamento{
	
	position: relative;

	.container{
		position: absolute;
		left: 0;
		right: 0;
		top: 15px;
	}
	.nome-da-loja{
		display: inline-block; 
		margin-top: 20px;

		.uma-cor{
			color: inherit;
		}
	}

	iframe{
		width: 100%;
	}
}

.footer-funcionamento{
	.nome-da-loja{
		color: white;
		margin-top: -5px;
		.uma-cor, .duas-cor{
			color: inherit;
		}
	}
	.servicos-da-loja{
		margin-top: 0px;
	}

	.midias{
		padding-top: 40px; 
		padding-left: 132px;
	}
}

.logo-da-empresa{
	
	margin-right: 20px;
	vertical-align: top;
	margin-top: 0px;
	float: left;
}

.horarios-e-mapa{
	display: flex;
	flex: auto;
	flex-direction: row;

	li{
		background: $corMain;
		color: white;
		padding: 10px;
		font-size: 16px;
		.icone i{
			color: white;
			font-size: 30px;
			margin: 5px;
		}

		&hover{
			background:#636363;
		}
	}
}

.horario,
.mapa{
	display: inline-block;
	text-align: left;
}

.horario{ margin-top: 10px; }

.icone{ 
	display: inline-block; 
	vertical-align: top;
	margin-right: 10px;
	i{
		color: $corMain;
		font-size: 24px;
		margin-top: 5px;
	}
}

.ver-google-maps{
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 12px;
	margin-top: 10px;
	text-transform: uppercase;
	display: inline-block;
	@include transition(0.3s);

	i{
		vertical-align: top;
		font-size: 18px;
		@include transition(0.3s);
		margin-top: -1px;
	}

	&:hover{
		i{
			margin-left: 10px;
		}
	}
}


// Footer
.whatsapp-fixed {
    position: fixed;
    bottom: 30px;
    right: 20px;
    
    z-index: 10000000;
}
footer{
	position: relative;

	.box-footer{
		padding-top: 60px;
		padding-bottom: 30px;
		background: $preto;
	}

	.atendimento{
		display: flex;
		flex-direction: column-reverse;
		li{
			padding: 0;
			display: block;
			margin-bottom: 30px;
			color: #e5e5e5;
			border-right: none;
			&:last-child{
				padding: 0;
			}
		}
	}
}

#map{
    width: 100%;
    height: 380px;
    background: $preto;
}

ul.lojas-mapa{
    display: flex;
    flex: auto;
    flex-direction: row;
    li{
        position: relative;
        flex: 1;
        a{
            display: block;
            padding: 10px 5px;
            font-size: 13px;
            color: $branco;
            background-color: #3f3f3f;
            img{
                float: left;
                margin: 2px 10px;
            }
            &.active, &:hover{
                background-color: $secondary-color;
            }
        }
        i{
            position: absolute;
            font-size: 22px;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.js-showmap{
  height:100%;
}
.maps-full{
    position: relative;
    
    >.container{
        position: absolute;
        top: -56px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}
.map-content{
    display: none;
    iframe{
        width: 100%;
        height: 350px;
    }
    &.active{
        display: block;
    }
}

.texto-horario{ 
	display: inline-block; 
	span{ display: block; }
}

.tag-coluna{
	color: #e5e5e5;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 18px;
	margin-bottom: 20px;
	display: inline-block;
}

.menu-footer{
	li{
		margin-top: 7px;
		margin-bottom: 7px;
		a{
			color: #e5e5e5;
			font-size: 14px;
			@include transition(0.1s);
			&:hover{
				color: $corMain;
				font-weight: 800;
			}
		}
	}
}

.ms-footer{
	li{
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		i{
			color: $preto;
			background: #636363;
			width: 25px;
			height: 25px;
			text-align: center;
			line-height: 25px;
			font-size: 15px;
			@include transition(0.3s);
			&:hover{
				background: #e5e5e5;
			}	
		}
	}
}

.ass-lua{
	margin-top: 70px;
	border-top: solid 1px #636363;
	padding-top: 20px;
}

.ass-footer{
	display: block;
	color: #636363;
	font-size: 12px;
}

.loading-wrapper {
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	font-weight: bold;
	font-family: Arial;
	font-size: 14px;
	height: 2000px;
	left: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 9999;
	display: none;
  
	.loading-content {
	  width: 212px;
	  height: 100px;
	  position: fixed;
	  top: 40%;
	  left: 50%;
	  margin-left: -111px;
	}
  }
@charset "UTF-8";
/*===========================================
	VARIAVEIS
============================================*/
/* Cor padrão branca*/
/* Cor Padrão Preta - Tom de Preto com base no Layout */
/* Cor primária do site */
/* Cor da seta dos Sliders */
/* BG que envolve as setas */
/* Cor do Texto do botão de oferta e oferta menores */
/* Fonte padrão de todo o Site */
/*===========================================
	BASE
============================================*/
body {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #1b1b1b;
  padding-top: 56px;
}

h1, h2, h3, h4, h5 {
  margin: 0px;
  font-weight: inherit;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:focus {
  outline: none;
  box-shadow: none;
}
a:active {
  text-decoration: none;
  color: inherit;
}

/*===========================================
	HEADER
============================================*/
header {
  background: #fff;
  box-shadow: 0px 1px 3.92px 0.08px rgba(0, 0, 0, 0.3);
}

.logo {
  font-size: inherit;
}

.atendimento {
  display: inline-block;
}
.atendimento li {
  display: inline-block;
  padding-right: 25px;
  padding-left: 25px;
  border-right: solid 1px #636363;
}
.atendimento li .icone-atendiento {
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
  vertical-align: sub;
  color: #636363;
}
.atendimento li .numero-atendimento {
  display: inline-block;
}
.atendimento li .numero-atendimento span {
  display: block;
  font-size: 14px;
  color: #636363;
}
.atendimento li .numero-atendimento strong {
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #636363;
}
.atendimento li:last-child {
  padding-right: 0;
  border-right: none;
}
.atendimento li.btn-whatsapp {
  background-color: #5cb85c;
  padding: 5px 25px;
  border-radius: 4px;
}
.atendimento li.btn-whatsapp i, .atendimento li.btn-whatsapp span, .atendimento li.btn-whatsapp strong {
  color: #fff;
}
.atendimento li.btn-whatsapp a {
  color: #fff;
  font-size: 14px;
}
.atendimento.footer li .icone-atendiento {
  color: #fff;
}
.atendimento.footer li .numero-atendimento span, .atendimento.footer li .numero-atendimento strong {
  color: #fff;
}

.sub-header {
  padding: 20px 0px;
  background: #fd1233;
  color: #fff;
}

.nome-duas-cores {
  margin-bottom: 0;
}

.uma-cor, .duas-cor {
  color: #1b1b1b;
  font-size: 32px;
  font-style: italic;
}

.servicos-da-loja {
  margin-top: -12px;
}

.uma-cor {
  font-weight: 700;
}

.duas-cor {
  font-weight: normal;
}

.menu li {
  display: inline-block;
  padding-right: 25px;
  position: relative;
  padding: 10px;
}
.menu li a {
  text-transform: uppercase;
  font-weight: 600;
}
.menu li a:hover {
  text-decoration: underline;
}
.menu li .sub-menu {
  position: absolute;
  top: 32px;
  left: 0;
  background: #fd1233;
  z-index: 200;
  display: none;
}
.menu li .sub-menu li {
  width: 250px;
  margin-right: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 30px;
  padding-bottom: 0;
  padding-right: 0;
}
.menu li .sub-menu li a {
  display: block;
  color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: solid 1px #fff;
}
.menu li .sub-menu li:hover {
  background: #252525;
}
.menu li .sub-menu li:hover a {
  border-color: #252525;
  text-decoration: none;
}
.menu li .sub-menu li:last-child a {
  border-bottom: none;
}
.menu li:hover .sub-menu {
  display: block;
}

.icones-leads {
  text-align: right;
  margin-top: 35px;
}
.icones-leads span {
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
}
.icones-leads span i {
  font-size: 25px;
}
.icones-leads span .fa-mobile {
  font-size: 30px;
  vertical-align: top;
}

@media (min-width: 768px) {
  header {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  header #faixa-cima {
    display: none;
  }
}
#header {
  background-color: #18131f;
  color: white;
  padding: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
}
#header .scrollable {
  height: 90%;
  overflow: auto;
  position: absolute;
  width: 90%;
}
#header .menu {
  top: 56px;
  width: 750px;
  min-height: 100vh;
  position: absolute;
  background: #18131f;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 30px;
  z-index: 1001;
  left: -100%;
  transition: all 0.3s linear;
  animation: slideleft 0.5s forwards;
}
#header .menu.show {
  animation: slideright 0.5s forwards;
}
#header .menu .menu-desktop {
  width: 100%;
}
#header .menu .menu-desktop li {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  padding-left: 0;
  border-bottom: solid 2px white;
  margin-bottom: 15px;
  text-decoration: none;
}
#header .menu .menu-desktop li a {
  margin-left: 10px;
  display: block;
}
#header .menu .menu-desktop li a:first-child {
  margin-left: 0;
}
#header .menu .menu-desktop li a:visited, #header .menu .menu-desktop li a:link {
  color: #fff;
}
#header .menu .menu-desktop li.midias a {
  display: inline-block;
}
#header .menu .menu-desktop li .submenu {
  display: none;
  padding-left: 30px;
}
#header .menu .menu-desktop li.open-menu.open {
  border-bottom: none;
  margin-bottom: 0;
}
#header .menu .menu-desktop li.open-menu.open a {
  padding-bottom: 15px;
  border-bottom: solid 2px white;
  margin-bottom: 30px;
}
#header .menu .menu-desktop li.open-menu.open .submenu {
  display: block;
}
#header .menu .menu-desktop li.open-menu.open .submenu a {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
#header .container-fluid {
  padding-top: 5px;
  padding-bottom: 6px;
}
#header .btn-menu {
  cursor: pointer;
  margin-top: 7px;
  min-height: 38px;
}
#header .btn-menu button {
  background: none;
  border: none;
  outline: none;
  float: left;
}
#header .btn-menu button span {
  display: block;
  background-color: white;
  width: 40px;
  margin-bottom: 10px;
  height: 2px;
  border-radius: 5px;
  transition: all 0.1s linear;
}
#header .btn-menu button.open span:first-child {
  transform: translate(5px, 12px) rotate(-45deg) scalex(0.8);
}
#header .btn-menu button.open span:nth-child(2) {
  height: 0;
  margin: 0;
}
#header .btn-menu button.open span:last-child {
  transform: translate(5px, 0px) rotate(45deg) scalex(0.8);
  margin: 0;
}
#header .btn-menu button:focus {
  outline: none;
}
#header .btn-menu .menu-text {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 55px;
}
#header .contato {
  margin: 0;
  padding-top: 5px;
  position: absolute;
  right: 15px;
}
#header .contato a {
  color: black;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 10px;
  display: inline-block;
}
#header .contato i {
  position: relative;
  font-size: 18px;
  top: 2px;
  right: 5px;
}
#header .contato .btn-phone {
  background-color: #00c8fa;
  margin-left: 15px;
}
#header .contato .btn-whats {
  background-color: #63bf00;
  margin-left: 15px;
}
#header .contato .btn-cidade {
  background-color: #00c8fa;
}
#header .local-info {
  position: absolute;
  right: -10px;
  top: 56px;
}
#header .local-info .bg-local {
  background-color: #00c8fa;
  padding: 8px 30px 8px 25px;
  transform: skewX(20deg);
}
#header .local-info .bg-local form {
  transform: skewX(-20deg);
  color: black;
}
#header .local-info .bg-local form label {
  font-size: 14px;
}
#header .local-info .bg-local form select {
  background: none;
  box-shadow: none;
  border: none;
  font-weight: 600;
  margin-top: -5px;
  color: black;
}

#box-phone h5 {
  font-size: 18px;
  font-weight: 600;
  color: #1b1b1b;
  margin: 20px 0;
  text-transform: uppercase;
}
#box-phone .btn-phone {
  background-color: #fd1233;
  color: #fff;
  padding: 5px 25px;
  margin-bottom: 10px;
  transform: skewX(-20deg);
  border-radius: none;
}
#box-phone .btn-phone i, #box-phone .btn-phone strong {
  display: inline-block;
  transform: skewX(20deg);
}

#box-whats h5 {
  font-size: 18px;
  font-weight: 600;
  color: #1b1b1b;
  margin: 20px 0;
  text-transform: uppercase;
}
#box-whats .btn-whats {
  background-color: #63bf00;
  color: #fff;
  padding: 5px 25px;
  margin-bottom: 10px;
  transform: skewX(-20deg);
  border-radius: none;
}
#box-whats .btn-whats i, #box-whats .btn-whats strong {
  display: inline-block;
  transform: skewX(20deg);
}

@media (max-width: 992px) {
  #header .menu {
    width: 550px;
  }
  #header .contato {
    padding-top: 5px;
  }
  #header .contato a {
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  #header .container-fluid {
    padding-bottom: 12px;
  }
  #header .local-info {
    top: 58px;
    width: 275px;
  }
  #header .local-info form select {
    width: 145px;
    display: inline-block;
  }
}
@keyframes slideright {
  100% {
    left: 0%;
  }
}
@keyframes slideleft {
  0% {
    left: -100%;
  }
}
/*===========================================
	CONTENT
============================================*/
.mega-box-slider {
  position: relative;
  width: 100%;
  height: auto;
}

.mega-box-slider {
  position: relative;
}
.mega-box-slider .seta-esquerda,
.mega-box-slider .seta-direita {
  z-index: 100;
  color: #fef0db;
  position: absolute;
  top: 40%;
  display: none;
  width: 50px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: none;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 50px;
  border: 0;
  margin: 0;
  padding: 0;
}
.mega-box-slider .seta-esquerda:before,
.mega-box-slider .seta-direita:before {
  content: none !important;
}
.mega-box-slider:hover .seta-esquerda, .mega-box-slider:hover .seta-direita, .mega-box-slider:hover .seta-esquerda-mobile, .mega-box-slider:hover .seta-direita-mobile {
  display: block;
}
.mega-box-slider .seta-direita {
  right: 20px;
}
.mega-box-slider .seta-esquerda {
  left: 20px;
}
.mega-box-slider .seta-esquerda.mobile,
.mega-box-slider .seta-direita.mobile {
  display: none !important;
}

.slider-desktop {
  visibility: hidden;
}

.slider-mobile {
  visibility: hidden;
}

.slider-desktop .item-slider img {
  display: inline-block;
  max-width: 1920px;
  width: 100%;
}

@media (max-width: 700px) {
  .slider-desktop {
    display: none;
  }
  .slider-mobile {
    display: block;
  }
  .seta-esquerda,
  .seta-direita {
    display: none !important;
  }
  .box-slider-desktop .seta-esquerda.mobile,
  .box-slider-desktop .seta-direita.mobile {
    display: block !important;
  }
}
.slider-principal.--mobile {
  display: block;
}
.slider-principal.--desktop {
  display: none;
}
.slider-principal .container {
  padding: 0px;
}
.slider-principal .slick-slide:focus {
  outline: none;
}
.slider-principal .slick-slide img {
  max-width: 100%;
}
.slider-principal .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 0;
  margin-top: 20px;
}
.slider-principal .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-principal .slick-dots li.slick-active {
  background: #fd1233;
}

.seta-outro-direita,
.seta-outro-esquerda,
.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-simule-esquerda,
.seta-slider-simule-direita,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-semi-direita,
.seta-adicionais-direita,
.seta-adicionais-esquerda,
.seta-adicionais-esquerda-fotos,
.seta-adicionais-direita-fotos {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #322f26;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 40%;
}

.seta-adicionais-esquerda-fotos {
  display: block !important;
  position: relative;
  top: 200px;
}

.seta-adicionais-direita-fotos {
  display: block !important;
  position: relative;
  top: -210px;
  left: 97%;
}

@media (max-width: 768px) {
  .seta-adicionais-esquerda-fotos, .seta-adicionais-direita-fotos {
    display: none !important;
  }
}
.seta-adicionais-esquerda,
.seta-adicionais-direita {
  z-index: 1;
}

.seta-semi-esquerda,
.seta-semi-direita {
  top: 55%;
}

.seta-slider-de-carros-esquerda {
  left: 0px;
}

.seta-slider-de-carros-direita {
  right: 0px;
}

.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita {
  top: 53%;
}

.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-slider-simule-esquerda {
  left: 15%;
}

.seta-peca-direita,
.seta-semi-direita,
.seta-slider-simule-direita {
  right: 15%;
}

.seta-slider-simule-esquerda,
.seta-slider-simule-direita {
  top: 40%;
}

.item-slider {
  background-position: center center;
  background-size: cover;
}

.box-interessado span {
  display: block;
  font-weight: 900;
  font-size: 48px;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.box-interessado span.pequeno-texto-slider {
  font-size: 16px;
  margin-bottom: -10px;
}
.box-interessado span.preco-do-carro-slider {
  font-weight: 300;
  margin-top: -20px;
  margin-bottom: 20px;
}

.estou-interessado {
  display: inline-block;
  background-color: #62ddbe;
  box-shadow: inset 0px 3px 0px 0px rgba(179, 255, 255, 0.75);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 900;
  color: #fff;
}
.estou-interessado i {
  color: #fff;
  margin-left: 10px;
}
.estou-interessado:hover {
  color: #fff;
}

.box {
  margin-top: 50px;
}

.box-escolha-um-carro {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
}

.box-sobre-carro {
  padding-top: 35px;
  padding-bottom: 90px;
  position: relative;
}

.box-ofertas {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
  background-color: #edede3;
}

.box-titulo-e-sub-titulo {
  margin-bottom: 70px;
}
.box-titulo-e-sub-titulo h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  color: #1b1b1b;
}
.box-titulo-e-sub-titulo h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300;
  color: #1b1b1b;
}

.box-filtro span {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;
  display: block;
}

.filtro {
  display: inline-block;
  border: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 70px;
}
.filtro li {
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  border-right: solid 1px #7e7e7e;
}
.filtro li a {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  color: #636363;
}
.filtro li:last-child {
  border-right: none;
}
.filtro li.filtro-ativo a {
  font-weight: 400;
}

.slider-de-carros,
.slider-ofertas,
.slider-simule,
.slider-peca,
.slider-seminovos {
  height: 300px;
}
.slider-de-carros .slick-dots,
.slider-ofertas .slick-dots,
.slider-simule .slick-dots,
.slider-peca .slick-dots,
.slider-seminovos .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}
.slider-de-carros .slick-dots li,
.slider-ofertas .slick-dots li,
.slider-simule .slick-dots li,
.slider-peca .slick-dots li,
.slider-seminovos .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-de-carros .slick-dots li.slick-active,
.slider-ofertas .slick-dots li.slick-active,
.slider-simule .slick-dots li.slick-active,
.slider-peca .slick-dots li.slick-active,
.slider-seminovos .slick-dots li.slick-active {
  background: #fd1233;
}

.item-carros,
.item-interno-carros {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
}
.item-carros img,
.item-interno-carros img {
  width: 100%;
  text-align: center;
}

.peca-borda {
  border: solid 3px #c10022;
}
.peca-borda img {
  height: 259px;
}

.item-interno-carros {
  margin-right: 0px;
  margin-left: 0;
}
.item-interno-carros .a-partir {
  margin-bottom: 5px;
}
.item-interno-carros .confira-oferta, .item-interno-carros .confira-oferta-pecas {
  margin-top: 10px;
}

.box-ofertas {
  position: relative;
}
.box-ofertas .slick-arrow {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  top: 250px;
}
.box-ofertas .seta-slider-de-ofertas-esquerda {
  left: -85px;
}
.box-ofertas .seta-slider-de-ofertas-direita {
  right: -75px;
}

.slider-ofertas {
  height: auto;
}
.slider-ofertas .item-carros {
  outline: none;
  background: #fff;
  padding: 15px;
  text-align: center;
  position: relative;
  padding-top: 30px;
}
.slider-ofertas .item-carros p {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0;
}
.slider-ofertas .item-carros .modelo {
  font-size: 24px;
  font-weight: 800;
}
.slider-ofertas .item-carros .nome-versao {
  font-size: 16px;
  font-weight: 800;
}
.slider-ofertas .item-carros .pre-preco {
  font-size: 14px;
  font-weight: 400;
}
.slider-ofertas .item-carros .de {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
}
.slider-ofertas .item-carros .por {
  font-size: 24px;
  font-weight: 800;
  color: #fd1233;
}
.slider-ofertas .item-carros .pos-preco {
  font-size: 14px;
  font-weight: 600;
}
.slider-ofertas .item-carros .extras {
  font-size: 20px;
  font-weight: 800;
  color: #fd1233;
  padding: 5px 0px;
  margin-top: 10px;
  border-top: solid 1px #fd1233;
  border-bottom: solid 1px #fd1233;
}
.slider-ofertas .item-carros .extras p {
  display: none;
}
.slider-ofertas .item-carros .extras p.ativo {
  display: block;
}
.slider-ofertas .item-carros .condicoes {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  padding-bottom: 1px;
  border-bottom: solid 1px black;
}
.slider-ofertas .item-carros .confira-oferta {
  padding: 6px 90px;
  margin: 15px auto 0;
}
.slider-ofertas .item-carros a:active, .slider-ofertas .item-carros a:visited, .slider-ofertas .item-carros a:focus {
  text-decoration: none;
  color: inherit;
}

.carros-novos {
  margin-bottom: 50px;
  display: flex;
}

.slider-simule .item-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  border: solid 1px #eeeeee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 300px;
  overflow: hidden;
}

.slider-seminovos .item-carros {
  background: #fff;
}

.cor-semi .box-titulo-e-sub-titulo h3, .cor-semi .box-titulo-e-sub-titulo h1 {
  color: #fff;
}

.nome-do-carro {
  display: block;
  font-size: 18px;
  font-weight: 900;
  color: #000;
  text-transform: uppercase;
}

.a-partir {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.confira-oferta {
  display: inline-block;
  color: #fff;
  background: #fd1233;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transform: skewx(-20deg);
}
.confira-oferta:hover {
  background: #252525;
  color: #fff;
}
.confira-oferta strong {
  transform: skewx(20deg);
  display: block;
}

.simule-cor {
  background: #fafafa;
}

.no-margin {
  margin: 0;
}

.escolha-outro {
  margin-top: 30px;
  display: inline-block;
  background: #fd1233;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
  padding-right: 55px;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.escolha-outro i {
  margin-left: 20px;
}
.escolha-outro:hover {
  background: #bb3c2b;
  color: #fff;
}

.cor-semi {
  background: #1b1b1b;
}

.procurar-semi-novo {
  margin-bottom: 35px;
}
.procurar-semi-novo input {
  background: none;
  border: solid 1px #fff;
  height: 80px;
  color: #fff;
  border-right: none;
}
.procurar-semi-novo input:focus {
  outline: none;
}
.procurar-semi-novo .input-group-addon {
  background: none;
  border-color: #fff;
}
.procurar-semi-novo .input-group-addon i {
  color: #fff;
  font-size: 20px;
}

.box-atendimento-pos {
  padding-bottom: 120px;
  background-size: cover;
  background-position: center center;
  background-color: #edede3;
}
.box-atendimento-pos .box-titulo-e-sub-titulo h3, .box-atendimento-pos .box-titulo-e-sub-titulo h1 {
  color: #fff;
}
.box-atendimento-pos .col-md-4 {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  padding: 0 25px;
  min-height: 200px;
  position: relative;
  margin-top: 35px;
}
.box-atendimento-pos .col-md-4 .text {
  color: #8b3f29;
  font-weight: 800;
  width: 50%;
  text-align: left;
}
.box-atendimento-pos .col-md-4 .text h5 {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.box-atendimento-pos .col-md-4 .text p {
  font-size: 14px;
  color: #000;
}
.box-atendimento-pos .col-md-4 .plus {
  position: absolute;
  top: -10px;
  right: -5px;
}

.botao-pos-venda {
  display: inline-block;
  border-bottom: solid 5px transparent;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.botao-pos-venda img {
  vertical-align: top;
  margin-right: 25px;
}
.botao-pos-venda .texto-do-botao {
  display: inline-block;
  margin-top: 10px;
}
.botao-pos-venda .texto-do-botao span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: -8px;
}
.botao-pos-venda .texto-do-botao span strong {
  font-weight: 900;
}
.botao-pos-venda i {
  position: absolute;
  right: 25px;
  color: #fff;
  top: 30%;
  font-size: 30px;
}
.botao-pos-venda.pos-whats {
  background: #35a648;
  border-color: #40c957;
}
.botao-pos-venda.pos-whats:hover {
  background: #40c957;
  border-color: #35a648;
}
.botao-pos-venda.pos-pecas {
  background: #228bab;
  border-color: #36a9cc;
}
.botao-pos-venda.pos-pecas:hover {
  background: #36a9cc;
  border-color: #228bab;
}
.botao-pos-venda.pos-revisao {
  background: #b45545;
  border-color: #ff7c66;
}
.botao-pos-venda.pos-revisao:hover {
  background: #ff7c66;
  border-color: #b45545;
}

.renault-atendimento .box-titulo-e-sub-titulo h3, .renault-atendimento .box-titulo-e-sub-titulo h1 {
  color: #1b1b1b;
}
.renault-atendimento .botao-pos-venda {
  background: #252525;
  border-color: #636363;
  padding-top: 10px;
  padding-bottom: 10px;
}
.renault-atendimento .botao-pos-venda:hover {
  background: #636363;
  border-color: #252525;
}
.renault-atendimento .col-md-4 {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  padding: 0 25px;
  min-height: 200px;
  position: relative;
  margin-top: 25px;
}
.renault-atendimento .col-md-4 .text {
  color: #8b3f29;
  font-weight: 800;
  width: 50%;
  text-align: left;
}
.renault-atendimento .col-md-4 .text h5 {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.renault-atendimento .col-md-4 .text p {
  font-size: 14px;
  color: #000;
}
.renault-atendimento .col-md-4 .plus {
  position: absolute;
  top: -10px;
  right: -5px;
}

.pequeno-texto {
  text-align: center;
  margin-bottom: 50px;
}
.pequeno-texto p {
  font-size: 18px;
  font-weight: 300;
}

.box-mini-mapa {
  background: #fd1233;
  padding-top: 15px;
  padding-bottom: 15px;
}
.box-mini-mapa span {
  color: #fff;
  text-transform: uppercase;
}
.box-mini-mapa span a {
  font-weight: 700;
}

.nome-da-categoria {
  padding-bottom: 15px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.nome-da-categoria span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  color: #636363;
}

.sobre-pagina {
  border: solid 1px #e5e5e5;
  display: inline-block;
  padding-left: 95px;
  padding-right: 95px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 0;
  margin-top: 0px;
  background: none;
}
.sobre-pagina .box-titulo-e-sub-titulo {
  margin-bottom: 50px;
}
.sobre-pagina .pequeno-texto {
  margin-bottom: 30px;
  text-align: left;
}
.sobre-pagina .botao-pos-venda, .sobre-pagina .pos-whats {
  padding-top: 10px;
  margin-top: 50px;
}

.box-imagem-interna {
  height: 380px;
}
.box-imagem-interna img {
  width: 100%;
  max-width: 1180px;
}

.box-fotos-empresa {
  background: #fafafa;
  padding-top: 55px;
  padding-bottom: 85px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  margin-top: 50px;
}

.item-galeria {
  width: 100%;
  height: 370px;
  background-position: center center;
  background-size: cover;
}

.item-pager-galeria {
  display: inline-block;
  margin-right: -1px;
}

.pager-galeria {
  margin-bottom: 50px;
}

.ligamos-pra-voce {
  text-align: center;
  padding-bottom: 22px;
  border-color: #252525;
  background: #636363;
  padding-top: 10px;
}
.ligamos-pra-voce img {
  left: 30px;
}
.ligamos-pra-voce .texto-do-botao {
  margin-top: 15px;
}
.ligamos-pra-voce .texto-do-botao span {
  display: inline-block;
}
.ligamos-pra-voce:hover {
  background: #252525;
  border-color: #636363;
}

.box-carros-novos {
  padding-bottom: 60px;
}

.box-contato .sobre-pagina {
  display: block;
}

.icone-telefone {
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
  margin-top: 10px;
}
.icone-telefone i {
  font-size: 20px;
}

.caixa-numero {
  display: inline-block;
  margin-left: 15px;
}
.caixa-numero span {
  display: block;
  font-size: 14px;
}

.contato {
  margin-bottom: 50px;
}
.contato textarea, .contato input {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.botao-e-informacao {
  margin-bottom: 20px;
}

.botao-mais-infos {
  width: 100%;
  position: relative;
  background: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}
.botao-mais-infos span {
  font-size: 16px;
  font-weight: 300;
}
.botao-mais-infos .fa-plus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 1;
}
.botao-mais-infos .fa-minus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 0;
}

.box-mais-info {
  background: #eeeeee;
  padding-top: 35px;
  padding-bottom: 35px;
}

.texto-mais-info {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.botoes-menores {
  display: inline-block;
  color: #fff;
  background: #252525;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.botoes-menores:hover {
  color: #fff;
  background: #fd1233;
}

.menor-ativo {
  background: #fd1233;
  margin-left: 30px;
}

.mais-ativo {
  background: #636363;
  color: #fff;
}
.mais-ativo .fa-plus {
  opacity: 0;
}
.mais-ativo .fa-minus {
  opacity: 1;
}

.box-escolha-carro {
  position: relative;
  /*margin-top: 100px;*/
  margin-bottom: 100px;
}
.box-escolha-carro .seta-slider-simule-esquerda,
.box-escolha-carro .seta-slider-simule-direita {
  top: 20%;
}

.interna-consorcio {
  margin-top: 0;
  padding-top: 40px;
  background: #fafafa;
}

.card-escolha-versao {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
}

.nome-e-ano {
  font-size: 48px;
  display: block;
}
.nome-e-ano strong {
  font-weight: 900;
}

.versao-do-carro {
  font-size: 18px;
  display: block;
  margin-bottom: 25px;
}
.versao-do-carro strong {
  font-weight: 900;
}

.texto-legal {
  font-size: 12px;
  color: #636363;
}

.imagem-do-carro {
  text-align: center;
  margin-bottom: 30px;
}
.imagem-do-carro img {
  /*		width: 100%;
  		max-width: 225px;*/
}

.card-escolha-versao .imagem-do-carro img {
  width: 100%;
  display: inline-block;
  max-width: 280px;
}

.escolha-versao {
  height: 35px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 25px;
  font-size: 14px;
}

.box-de-preco-da-versao .parcelas-consorcio {
  display: block;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: #636363;
}
.box-de-preco-da-versao .valor-consorcio {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #636363;
  font-size: 30px;
  font-weight: 300;
}
.box-de-preco-da-versao .valor-consorcio em {
  font-style: normal;
  text-transform: none;
  font-size: 14px;
}
.box-de-preco-da-versao .credito-consorcio {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #636363;
  margin-top: 25px;
}
.box-de-preco-da-versao .valor-do-credito-consorcio {
  text-align: center;
  font-size: 18px;
  color: #636363;
  display: block;
}
.box-de-preco-da-versao .valor-do-credito-consorcio strong {
  font-weight: 900;
}

.card-plano-consorcio {
  padding-top: 35px;
  padding-bottom: 12px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-plano-consorcio .tempo-do-plano,
.card-plano-consorcio .contemplados {
  color: #252525;
  font-size: 14px;
  display: block;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano {
  color: #252525;
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano strong {
  font-weight: 900;
}

.titulo-escolha-um-plano {
  display: inherit;
  margin-top: 60px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 50px;
}

.card-form-financiamento {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-form-financiamento h1 {
  display: inherit;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
}

.custom-modal {
  display: inline-block;
  background: #fd1233;
  padding-top: 45px;
  padding-left: 100px;
  padding-right: 100px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border-top-right-radius: 0;
  position: relative;
  padding-bottom: 50px;
}

.novo-modal {
  padding: 0;
  background: #fff;
  color: #1b1b1b;
}
.novo-modal .modal-footer {
  background: #fd1233;
  text-align: center;
}
.novo-modal a.close-modal {
  display: none;
}
.novo-modal .fechar-modal {
  background: none;
  right: -5px;
}
.novo-modal .fechar-modal a {
  color: inherit;
}
.novo-modal .fechar-modal i {
  color: #1b1b1b;
}
.novo-modal .tag-preencher span, .novo-modal .tag-preencher i {
  color: black;
  font-weight: 700;
  font-size: 26px;
}
.novo-modal .loja p {
  font-weight: 600;
  text-transform: uppercase;
}
.novo-modal .btn {
  border-radius: 0;
}

#box-modal-floater {
  padding: 0px 0px 0px 0px;
  background: white;
}
#box-modal-floater .tag-preencher span {
  color: black;
}

.revisao-modal {
  max-width: 650px;
}

.tag-preencher {
  width: 100%;
  text-align: center;
  padding: 9px 0;
}
.tag-preencher span {
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
}
.tag-preencher i {
  color: #fff;
  font-size: 40px;
}

.modal-contato label {
  color: #FFF;
}
.modal-contato input, .modal-contato textarea, .modal-contato select {
  color: #FFF;
  border: none;
  border-bottom: solid 1px #c2c2c2;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-contato input:focus, .modal-contato textarea:focus, .modal-contato select:focus {
  border: solid 1px #fff;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.modal-contato input[disabled], .modal-contato input[readonly], .modal-contato textarea[disabled], .modal-contato textarea[readonly], .modal-contato select[disabled], .modal-contato select[readonly] {
  background-color: #da0220;
}
.modal-contato select {
  padding: 6px;
}
.modal-contato select option {
  color: #1b1b1b;
}
.modal-contato textarea {
  min-height: 80px;
}
.modal-contato ::-webkit-input-placeholder {
  color: #fff;
}
.modal-contato :-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
.modal-contato ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.modal-contato :-ms-input-placeholder {
  color: #fff;
}
.modal-contato span {
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-contato .enviar-modal {
  background: #313131;
  color: #fff;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
}
.modal-contato .enviar-modal i {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 30px;
  color: #fff;
}

.fechar-modal {
  width: 60px;
  right: 60px;
  position: absolute;
  right: 0;
  top: 0;
  background: #313131;
  text-align: center;
  line-height: 45px;
  z-index: 100;
}
.fechar-modal i {
  color: #fff;
  font-size: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.fechar-modal:hover i {
  color: #fd1233;
}

.box-quadro-de-pecas {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.titulo-box-filtro-pecas {
  background: #333333;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: solid 1px #636363;
}
.titulo-box-filtro-pecas span {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}

.pesquisa-filtro {
  background: #252525;
  border-bottom: solid 1px #636363;
  padding: 10px;
}
.pesquisa-filtro .input-group,
.pesquisa-filtro .form-group {
  width: 100%;
}
.pesquisa-filtro input {
  border: solid 1px #fff;
  height: 40px;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  border-right: none;
}
.pesquisa-filtro input:focus {
  border: solid 1px #fff;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.pesquisa-filtro ::-webkit-input-placeholder {
  color: #fff;
}
.pesquisa-filtro :-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
.pesquisa-filtro ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.pesquisa-filtro :-ms-input-placeholder {
  color: #fff;
}
.pesquisa-filtro .input-group-addon {
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-left: none;
  border-color: #fff;
}
.pesquisa-filtro .input-group-addon i {
  color: #fff;
}

.clique-carro {
  display: block;
  background: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: solid 1px #636363;
  padding-left: 10px;
  padding-right: 10px;
}
.clique-carro span {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
}
.clique-carro:hover {
  background: #fd1233;
}
.clique-carro i {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.clique-carro.feita-escolha {
  background: #fd1233;
  border-color: #fd1233;
}
.clique-carro.feita-escolha span {
  font-weight: 700;
}
.clique-carro.feita-escolha i {
  opacity: 1;
}
.clique-carro.feita-escolha .fa-times {
  position: absolute;
  right: 0;
  top: 25px;
}

.barra-ordenar {
  background: #e3e3e3;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
.barra-ordenar span {
  text-transform: uppercase;
  color: #0f0f0f;
}
.barra-ordenar .nome-da-barra {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #1b1b1b;
}

.ordenar {
  display: inline-block;
  margin-left: 10px;
}
.ordenar li {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border-right: solid 1px #0f0f0f;
}
.ordenar li a {
  text-transform: uppercase;
  color: #0f0f0f;
}
.ordenar li:last-child {
  border-right: none;
}

.caixa-de-resultados .item-carros {
  width: 265px;
  margin-left: 7px;
  margin-bottom: 30px;
  margin-right: 7px;
}

.item-carros-pecas {
  width: 265px;
  margin-left: 7px;
  margin-bottom: 30px;
  margin-right: 7px;
}

.botoes-internos-carro {
  background: none;
  margin-top: 70px;
  margin-bottom: 70px;
}

.box-versoes-com-mais {
  padding-bottom: 35px;
}

.box-fotos-do-carro {
  background: #fafafa;
  border-top: solid 1px #e5e5e5;
  padding-top: 35px;
  padding-bottom: 35px;
}

.interior-exterior {
  margin-bottom: 60px;
  position: relative;
}

.interior,
.exterior {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  background: #636363;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  padding-left: 65px;
  padding-right: 65px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.interior:hover,
.exterior:hover {
  background: #fd1233;
  color: #fff;
}
.interior.ativo-in-ex,
.exterior.ativo-in-ex {
  background: #fd1233;
  color: #fff;
}

.ativo-in-ex:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 12px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #c10022;
}

.cards-adicionais {
  margin-bottom: 35px;
  position: relative;
}
.cards-adicionais .slick-slide:focus {
  outline: none;
}

.card-adicional {
  margin-right: 10px;
  margin-left: 10px;
  width: 290px;
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eeeeee;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.card-adicional .confira-oferta, .card-adicional .confira-oferta-pecas {
  margin-bottom: 20px;
}
.card-adicional:hover {
  opacity: 1;
}
.card-adicional.adicional-ativo {
  opacity: 1;
}

.nome-do-carro-adicional,
.modelo-do-carro-adicional,
.preco-do-carro-adicional {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}
.nome-do-carro-adicional strong,
.modelo-do-carro-adicional strong,
.preco-do-carro-adicional strong {
  font-weight: 900;
}

.modelo-do-carro-adicional {
  font-size: 18px;
}

.preco-do-carro-adicional {
  font-size: 16px;
  margin-bottom: 20px;
}

.thumb-foto-adicional {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.thumb-foto-adicional img {
  display: inline-block;
  width: 100%;
  max-width: 185px;
}

.adicionais-do-carro {
  cursor: pointer;
  display: none;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #1b1b1b;
}
.adicionais-do-carro li {
  color: #fff;
}

.ver-itens-adicional {
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
  color: #929292;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-itens-adicional:hover {
  color: #252525;
}

.seta-adicionais-direita {
  right: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita:hover {
  background: #fd1233;
  border-color: #fd1233;
}
.seta-adicionais-direita:hover i {
  color: #fff;
}

.seta-adicionais-esquerda {
  left: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda:hover {
  background: #fd1233;
  border-color: #fd1233;
}
.seta-adicionais-esquerda:hover i {
  color: #fff;
}

.seta-versao-esquerda,
.seta-ultimo-esquerda,
.seta-ultimo-direita,
.seta-versao-direita {
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  top: -50px;
  color: #828282;
  font-weight: 900;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: top;
  cursor: pointer;
}
.seta-versao-esquerda i,
.seta-ultimo-esquerda i,
.seta-ultimo-direita i,
.seta-versao-direita i {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: solid 1px #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #252525;
  text-align: center;
  line-height: 40px;
  margin-right: 15px;
  font-size: 24px;
}
.seta-versao-esquerda:hover,
.seta-ultimo-esquerda:hover,
.seta-ultimo-direita:hover,
.seta-versao-direita:hover {
  color: #252525;
}
.seta-versao-esquerda:hover i,
.seta-ultimo-esquerda:hover i,
.seta-ultimo-direita:hover i,
.seta-versao-direita:hover i {
  background: #fd1233;
  color: #fff;
  border-color: #fd1233;
}

.seta-versao-esquerda {
  left: 0;
}

.seta-versao-direita {
  right: 0;
}
.seta-versao-direita i {
  margin-left: 10px;
  margin-right: 0;
}

.item-outro-slider {
  display: inline-block;
  width: 280px;
  background: #fff;
  height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}

.thumb-imagem-outro {
  margin-bottom: 25px;
}
.thumb-imagem-outro img {
  width: 100%;
}

.outro-titulo {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  color: #636363;
  margin-bottom: 30px;
  text-align: center;
}

.outro-texto {
  padding-left: 15px;
  padding-right: 15px;
}
.outro-texto p {
  font-weight: 300;
  font-size: 12px;
  color: #636363;
}

.outro-slider .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 50px;
}
.outro-slider .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.outro-slider .slick-dots li.slick-active {
  background: #fd1233;
}

.seta-outro-direita {
  right: 0px;
}

.seta-outro-esquerda {
  left: 0px;
}

.box-escolha-um-carro .item-versao .sobre-pagina {
  margin-top: 0;
}
.box-escolha-um-carro .item-versao .sobre-pagina .card-adicional {
  opacity: 1;
  border: none;
}

.box-escolha-um-carro .setas-versoes .seta-ultimo-direita,
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  top: 40%;
  z-index: 100;
  background: #fff;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-direita {
  right: -25px;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  left: -25px;
}

.item-pager-galeria {
  cursor: pointer;
}

.esconder-imagem {
  height: 0px;
  overflow-y: hidden;
  display: block !important;
}

.box-radios {
  text-align: center;
}
.box-radios .radio {
  display: inline-block;
  margin-right: 10px;
}

.nome-da-peca, .nome-da-peca-destaque {
  display: block;
  font-size: 18px;
  font-weight: 900;
  color: #636363;
  text-transform: uppercase;
}

.codigo {
  display: block;
  font-size: 10px;
  font-weight: 700;
  color: #777;
  margin-bottom: 20px;
}

.a-partir-pecas-de span {
  text-decoration: line-through;
}

.parcelas {
  color: #636363;
}
.parcelas strong {
  color: #fd1233;
}

.a-partir-pecas {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #d01b27;
}
.a-partir-pecas small {
  font-weight: 600;
  font-size: 70%;
  color: #636363;
}

.confira-oferta-pecas {
  display: inline-block;
  width: 100%;
  color: #fff;
  background: #fd1233;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.confira-oferta-pecas:hover {
  background: #252525;
  color: #fff;
}

.items-do-filtro a {
  border-right: solid 1px #636363;
}

.box-interna-pecaacessorio {
  margin-top: 50px;
}
.box-interna-pecaacessorio .nome-pecaacessorio-interna {
  color: #fd1233;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 15px;
}
.box-interna-pecaacessorio .fotos-do-item {
  position: relative;
}
.box-interna-pecaacessorio .fotos-do-item img {
  width: 100%;
  max-height: 580px;
}
.box-interna-pecaacessorio .fotos-do-item .seta-esquerda,
.box-interna-pecaacessorio .fotos-do-item .seta-direita,
.box-interna-pecaacessorio .fotos-do-item .seta-direita-mobile,
.box-interna-pecaacessorio .fotos-do-item .seta-esquerda-mobile {
  z-index: 100;
  color: #FFF;
  position: absolute;
  top: 45%;
  display: none;
  width: 50px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 50px;
}
.box-interna-pecaacessorio .fotos-do-item:hover .seta-esquerda, .box-interna-pecaacessorio .fotos-do-item:hover .seta-direita, .box-interna-pecaacessorio .fotos-do-item:hover .seta-direita-mobile, .box-interna-pecaacessorio .fotos-do-item:hover .seta-esquerda-mobile {
  display: block;
}
.box-interna-pecaacessorio .fotos-do-item .seta-direita,
.box-interna-pecaacessorio .fotos-do-item .seta-direita-mobile {
  right: 0;
}
.box-interna-pecaacessorio .fotos-do-item .seta-esquerda,
.box-interna-pecaacessorio .fotos-do-item .seta-esquerda-mobile {
  left: 0;
}
.box-interna-pecaacessorio .galeria-thumb {
  background: #e6e6e6;
  padding-top: 10px;
  text-align: center;
}
.box-interna-pecaacessorio .galeria-thumb .item-thumbs {
  max-width: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.box-interna-pecaacessorio .galeria-thumb .item-thumbs img {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  max-width: 95px;
}

.caixa-descricao {
  border: solid 1px #313131;
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 50px;
}
.caixa-descricao .caixa-titulo {
  display: inline-block;
  width: 100%;
  padding-left: 15px;
  height: 60px;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
  background: #313131;
  line-height: 60px;
}
.caixa-descricao .info-descricao {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  padding: 10px 15px;
}

.lead-pecaacessorio {
  border-top: solid 1px #fff;
  background: #636363;
  text-align: center;
}

.tag-lead-interna {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
}

.form-interna-pecaacessorio input {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  color: #1b1b1b;
}
.form-interna-pecaacessorio textarea {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  color: #1b1b1b;
}

.box-inputs {
  padding-left: 10px;
  padding-right: 10px;
}

.tag-lead-menor {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  display: inline-block;
}

.box-radios .radio {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.box-radios .radio label {
  color: #615f6c;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}

.enviar-lead-interna {
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
  background: red;
  color: white;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}
.enviar-lead-interna i {
  font-size: 12px;
}
.enviar-lead-interna:hover {
  background-color: #cc0000;
}

.revisao-programada-banner {
  width: 100%;
  height: 260px;
  display: block;
  background: transparent url("../img/slider/revisao-programada.jpg") no-repeat scroll center center;
  background-size: cover;
}

.lead-revisaoprogramada {
  background-color: #fd1233;
  padding: 20px;
}

.table {
  width: 100%;
}
.table thead {
  font-weight: bold;
}

.table-car-links a {
  background-color: #fd1233;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.bold {
  font-weight: bold;
}

.peca-content {
  height: 200px;
}

.mega-box-slider .slide-left {
  display: none;
}
.mega-box-slider .slide-right {
  display: none;
}
.mega-box-slider:hover .slide-left {
  display: block;
}
.mega-box-slider:hover .slide-right {
  display: block;
}

/*===========================================
	ESTOQUE
============================================*/
.btn-estoque {
  background-color: #fd1233;
  color: #fff;
  font-weight: 600;
}
.btn-estoque:hover {
  background-color: #a80119;
  color: #fff;
}

.box-estoque {
  padding-top: 50px;
}
.box-estoque .ordenar-por span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 11px;
  color: #1b1b1b;
}
.box-estoque .ordenar {
  margin-top: 20px;
  margin-bottom: 20px;
}
.box-estoque .ordenar li {
  display: inline-block;
  border-right: solid 1px #aeaeae;
  padding-right: 10px;
  padding-left: 10px;
}
.box-estoque .ordenar li a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
  color: #1b1b1b;
}
.box-estoque .ordenar li a:hover {
  color: #fd1233;
}
.box-estoque .ordenar li:first-child {
  padding-left: 0;
}
.box-estoque .ordenar li:last-child {
  border-right: none;
}
.box-estoque .mostrar-quantidade {
  margin-top: 15px;
}
.box-estoque .mostrar-quantidade span {
  display: inline-block;
  font-size: 11px;
  color: #1b1b1b;
}
.box-estoque .mostrar-quantidade select {
  display: inline-block;
  width: auto;
  margin-left: 10px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  height: 30px;
  font-size: 11px;
}

.quantidade-de-paginas {
  text-align: right;
  border-top: solid 1px #c9c9c9;
  padding-top: 20px;
  padding-bottom: 20px;
}
.quantidade-de-paginas li {
  margin-left: 3px;
  margin-right: 3px;
  display: inline-block;
  background: #f2f2f2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.quantidade-de-paginas li a {
  color: #9f9f9f;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.quantidade-de-paginas li:hover {
  background: #fd1233;
}
.quantidade-de-paginas li:hover a {
  color: #fff;
}
.quantidade-de-paginas li.pagina-atual {
  background: #fd1233;
}
.quantidade-de-paginas li.pagina-atual a {
  color: #fff;
}

.box-principais {
  text-align: center;
}
.box-principais .botao-default {
  margin-top: 20px;
  margin-bottom: 50px;
  display: inline-block;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
  background: #252525;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.box-principais .botao-default i {
  font-size: 12px;
}
.box-principais .botao-default.todo-estoque {
  color: black;
}

.car-icon {
  display: inline-block;
  width: 20px;
  height: 21px;
  vertical-align: middle;
}
.car-icon.combustivel {
  background: url(../img/icones/icons_hover.png);
  background-position: -46px 0px;
  padding-left: 23px;
}
.car-icon.portas {
  background: transparent url("../img/icones/07.png") scroll no-repeat left center;
}
.car-icon.cor {
  background: transparent url("../img/icones/06.png") scroll no-repeat left center;
}
.car-icon.cambio {
  background: transparent url("../img/icones/05.png") scroll no-repeat left center;
}
.car-icon.ano {
  background: url(../img/icones/icons_hover.png);
  background-position: 0 0px;
  padding-left: 23px;
}
.car-icon.quilometragem {
  background: url(../img/icones/icons_hover.png);
  background-position: -24px 0px;
  padding-left: 23px;
}
.car-icon.motor {
  background: transparent url("../img/icones/02.png") scroll no-repeat left center;
}
.car-icon.car {
  background: transparent url("../img/icones/01.png") scroll no-repeat left center;
}

.card-carro {
  border: solid 1px #dcdcdc;
  border-top: 7px solid #fd1233;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}
.card-carro:hover {
  background-color: #fd1233;
  color: white;
}
.card-carro:hover .valor {
  color: white !important;
}
.card-carro:hover .cifrao {
  color: white !important;
}
.card-carro:hover .car-icon {
  color: white;
}
.card-carro:hover .car-icon.ano {
  background: url(../img/icones/icons_hover.png);
  background-position: 0 24px;
}
.card-carro:hover .car-icon.quilometragem {
  background: url(../img/icones/icons_hover.png);
  background-position: -24px 24px;
}
.card-carro:hover .car-icon.combustivel {
  background: url(../img/icones/icons_hover.png);
  background-position: -46px 24px;
}
.card-carro .tag-oferta {
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 140px;
  height: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
  border-top: 20px solid #fd1233;
  border-right: 15px solid transparent;
  border-left: 15px solid #fd1233;
}
.card-carro .tag-oferta span {
  position: absolute;
  width: 115px;
  top: -22px;
  left: -10px;
}
.card-carro .botao-default {
  padding: 0;
  display: block;
  position: absolute;
  bottom: -14px;
  background: #252525;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 55%;
  font-size: 12px;
  font-weight: 700;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  height: 29px;
  line-height: 29px;
}
.card-carro .botao-default i {
  font-size: 12px;
}
.card-carro .botao-default.ligamos-para-voce {
  background: #21a45c;
}
.card-carro .botao-default.ligamos-para-voce:hover {
  background: #26d775;
}
.card-carro ul.caixa-specs {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: center;
}
.card-carro ul.caixa-specs li {
  border: 1px solid #dcdcdc;
  border-bottom: none;
  border-left: none;
  color: #999;
  font-size: 10px;
  padding: 5px 2px;
  flex: 1;
}
.card-carro ul.caixa-specs li:first-child {
  border-left: none;
}
.card-carro ul.caixa-specs li:last-child {
  border-right: none;
}
.card-carro ul.caixa-specs li span.car-icon {
  width: auto;
  line-height: 20px;
}
.card-carro .caixa-whatsapp {
  text-align: center;
  background-color: #5cb85c;
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.caixa-foto {
  position: relative;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.caixa-foto img {
  width: 100%;
}
.caixa-foto .local-venda {
  background: #313131;
  color: #fff;
  font-size: 10px;
  padding: 2px 4px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
}
.caixa-foto .botao-comparar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 9px;
  display: inline-block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 3px 7px;
  background: #1b1b1b;
  opacity: 0.5;
}
.caixa-foto .botao-comparar:hover {
  background: #020202;
  opacity: 0.9;
}

.caixa-de-valor {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 160px;
}
.caixa-de-valor .marca-do-carro {
  display: inline-block;
  width: 100%;
}
.caixa-de-valor .nome-do-carro {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.caixa-de-valor .versao,
.caixa-de-valor .tipo-do-carro {
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 35px;
  text-transform: uppercase;
  margin: 0;
  font-size: 11px;
}
.caixa-de-valor .valor-do-carro .cifrao {
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 700;
}
.caixa-de-valor .valor-do-carro .valor {
  color: #1b1b1b;
  font-size: 24px;
  font-weight: 900;
}

/******
Interna carro
******/
.box-interna-carro {
  margin-top: 50px;
}
.box-interna-carro .marca-do-carro,
.box-interna-carro .nome-do-carro {
  display: inline-block;
}
.box-interna-carro .marca-do-carro {
  vertical-align: top;
  padding-top: 5px;
  margin-right: 10px;
}
.box-interna-carro .marca-do-carro img {
  display: inline-block;
}
.box-interna-carro .nome-do-carro-interna {
  color: #fd1233;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
}
.box-interna-carro .nome-do-carro-interna strong {
  font-size: 30px;
}
.box-interna-carro .fotos-do-carro {
  margin-top: 20px;
  position: relative;
}
.box-interna-carro .fotos-do-carro img {
  width: 100%;
  max-height: 580px;
}
.box-interna-carro .fotos-do-carro .seta-esquerda,
.box-interna-carro .fotos-do-carro .seta-direita,
.box-interna-carro .fotos-do-carro .seta-direita-mobile,
.box-interna-carro .fotos-do-carro .seta-esquerda-mobile {
  z-index: 100;
  color: #FFF;
  position: absolute;
  top: 45%;
  display: none;
  width: 50px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 50px;
}
.box-interna-carro .fotos-do-carro:hover .seta-esquerda, .box-interna-carro .fotos-do-carro:hover .seta-direita, .box-interna-carro .fotos-do-carro:hover .seta-direita-mobile, .box-interna-carro .fotos-do-carro:hover .seta-esquerda-mobile {
  display: block;
}
.box-interna-carro .fotos-do-carro .seta-direita,
.box-interna-carro .fotos-do-carro .seta-direita-mobile {
  right: 0;
}
.box-interna-carro .fotos-do-carro .seta-esquerda,
.box-interna-carro .fotos-do-carro .seta-esquerda-mobile {
  left: 0;
}
.box-interna-carro .galeria-thumb {
  background: #e6e6e6;
  padding-top: 10px;
  text-align: center;
}
.box-interna-carro .galeria-thumb .item-thumbs {
  max-width: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.box-interna-carro .galeria-thumb .item-thumbs img {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  max-width: 95px;
}

.quadro-informacoes-do-carro {
  margin-top: 20px;
  border: solid 1px #d6d6d6;
}
.quadro-informacoes-do-carro .item-informacao {
  height: 50px;
  line-height: 50px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 300;
}
.quadro-informacoes-do-carro .item-informacao img {
  display: inline-block;
  margin-right: 10px;
}
.quadro-informacoes-do-carro .item-informacao.cinza {
  background: #f7f7f7;
}

.caixa-caracteristica {
  border: solid 1px #313131;
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 50px;
}
.caixa-caracteristica .caixa-titulo {
  display: inline-block;
  width: 100%;
  padding-left: 15px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
  background: #313131;
  line-height: 60px;
}
.caixa-caracteristica .tag-principal {
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  color: #1b1b1b;
  font-size: 16px;
  margin-bottom: 30px;
}
.caixa-caracteristica .tag {
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  color: #1b1b1b;
  font-size: 16px;
  margin-bottom: 15px;
}
.caixa-caracteristica .info-caracteristica {
  display: inline-block;
  width: 100%;
  color: #1b1b1b;
  font-weight: 400;
  padding-left: 20px;
  font-size: 12px;
  margin-bottom: 30px;
  padding-right: 60px;
}

.lista-de-caracteristicas {
  padding-left: 20px;
  margin-bottom: 40px;
}
.lista-de-caracteristicas li {
  display: inline-block;
  border: solid 1px black;
  padding: 15px;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}
.lista-de-caracteristicas li span {
  font-size: 12px;
}
.lista-de-caracteristicas li:first-child {
  margin-left: 0;
}

.checks {
  margin-bottom: 30px;
  padding-left: 20px;
}

.opcional-check {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}
.opcional-check i {
  margin-right: 5px;
  font-size: 18px;
}

.marca-da-loja {
  display: inline-block;
  padding: 30px;
  text-align: center;
  border: solid 1px #dedede;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  vertical-align: top;
}
.marca-da-loja img {
  width: 100%;
  display: inline-block;
}

.contato-da-loja {
  display: inline-block;
}
.contato-da-loja span {
  display: inline-block;
  width: 100%;
  color: #717171;
  font-size: 12px;
}

.compartilhar {
  border-top: solid 1px #aaaaaa;
  padding-top: 10px;
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;
}
.compartilhar span {
  font-size: 12px;
}

.compartilhar-btn {
  text-align: center;
  width: 100%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-top: 20px;
}
.compartilhar-btn:hover {
  color: #fff;
}
.compartilhar-btn.whatsapp {
  background: #65bc54;
}
.compartilhar-btn.facebook {
  background: #3c6ecb;
}
.compartilhar-btn.youtube {
  background: #dc3333;
}
.compartilhar-btn.twitter {
  background: #52d4f9;
}
.compartilhar-btn.instagram {
  background: #e87e21;
}

.car-right-box {
  position: absolute;
}

.lead-preco {
  border-top: solid 1px #fff;
  background: #fd1233;
  text-align: center;
  padding: 10px;
}

.de {
  color: #ababab;
  font-size: 14px;
  margin-top: -11px;
  margin-bottom: -3px;
}
.de .valor-antes {
  text-decoration: line-through;
}

.valor-carro {
  background: #fd1233;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.valor-carro .cifrao {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
}
.valor-carro .valor {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
}

.lead-interna-carro small {
  color: #ddf;
}
.lead-interna-carro input, .lead-interna-carro select {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  color: #1b1b1b;
}
.lead-interna-carro textarea {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  height: 100px;
  color: #1b1b1b;
}

.tag-lead-interna {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
}

.tag-lead-menor {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  display: inline-block;
}

.box-radios .radio {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.box-radios .radio label {
  color: #615f6c;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}

.enviar-lead-interna {
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
  background: #252525;
  color: white !important;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}
.enviar-lead-interna i {
  font-size: 12px;
}
.enviar-lead-interna:hover {
  background-color: #0c0c0c;
}

/******
Busca Estoque
******/
.box-busca-avancada {
  background: #868686;
  color: #fff;
  border-bottom: solid 1px #d5d5d5;
  padding-top: 35px;
  padding-bottom: 25px;
}
.box-busca-avancada label {
  font-size: 14px;
}
.box-busca-avancada label > input[type=radio] {
  margin-left: 20px;
}
.box-busca-avancada .noUi-horizontal {
  padding: 0 10px;
  margin-top: 7px;
  height: 8px;
}
.box-busca-avancada .noUi-horizontal .noUi-handle {
  height: 18px;
}
.box-busca-avancada .noUi-horizontal .noUi-handle:before, .box-busca-avancada .noUi-horizontal .noUi-handle:after {
  height: 9px;
  top: 4px;
}
.box-busca-avancada button[type=submit] {
  margin-top: 15px;
}
.box-busca-avancada .btn-mais-filtro {
  color: white;
  text-decoration: none;
}

.caixa-avancada {
  background: #f7f7f7;
  margin-bottom: 50px;
}
.caixa-avancada .quantidade-encontrada {
  width: 100%;
  background: #fd1233;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.caixa-avancada .quantidade-encontrada .quantidade {
  font-size: 24px;
  font-weight: 900;
  color: #fff;
  display: inline-block;
  width: 100%;
}
.caixa-avancada .quantidade-encontrada span {
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
}
.caixa-avancada .selecionados {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.caixa-avancada .selecionados span {
  display: inline-block;
  width: 100%;
  color: #1b1b1b;
  font-size: 14px;
  margin-bottom: 20px;
}
.caixa-avancada .selecionados .item-selecionado {
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 15px;
}
.caixa-avancada .selecionados .item-selecionado .fechar {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  font-weight: 900;
  background: #fd1233;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
}
.caixa-avancada .selecionados .item-selecionado .nome-do-item {
  color: #1b1b1b;
  font-size: 12px;
  display: inline-block;
  width: inherit;
  margin-bottom: 0;
}
.caixa-avancada .tag {
  background: #1b1b1b;
  width: 100%;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}
.caixa-avancada .busca li a {
  text-align: center;
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: #1b1b1b;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.caixa-avancada .busca li a:hover {
  color: #fff;
  background: #fd1233;
}
.caixa-avancada .busca li a .fa-angle-right {
  position: absolute;
  right: 5%;
  top: 14%;
  font-size: 25px;
}
.caixa-avancada .busca li a.busca-estoque-ativa {
  color: #fff;
  background: #fd1233;
}
.caixa-avancada .busca li .sub-opcoes {
  max-height: 300px;
  overflow-y: auto;
  display: none;
  background-color: #fd1233;
  padding: 10px;
  color: #fff;
}
.caixa-avancada .busca li .sub-opcoes li a {
  padding-left: 15px;
  text-align: left;
  color: #fff;
}
.caixa-avancada .busca li .sub-opcoes li select {
  color: #000;
}
.caixa-avancada .form-estoque {
  padding: 0 10px;
}
.caixa-avancada .form-estoque .form-group {
  margin-bottom: 0px;
  margin-top: 10px;
}
.caixa-avancada .form-estoque .form-group .bg-main-color {
  background: #fd1233;
  border-radius: 5px;
  padding: 5px 0px;
  margin-top: 10px;
  width: 100%;
}
.caixa-avancada .form-estoque .form-group .form-buscar {
  border: none;
  background: none;
  color: white;
}
.caixa-avancada .encontre-aqui {
  background: #1b1b1b;
  padding: 10px;
}
.caixa-avancada .encontre-aqui .barra-de-pesquisa {
  margin: 0;
  height: 30px;
}
.caixa-avancada .encontre-aqui .barra-de-pesquisa .form-inline .form-group .input-group input {
  height: 30px;
}
.caixa-avancada .encontre-aqui .barra-de-pesquisa .form-inline .form-group .input-group .input-group-addon {
  padding-right: 5px;
}
.caixa-avancada .encontre-aqui .barra-de-pesquisa .form-inline .form-group .input-group .input-group-addon .botao-estoque-submit {
  height: 30px;
}
.caixa-avancada .encontre-aqui .barra-de-pesquisa .form-inline .form-group .input-group .input-group-addon i {
  font-size: 14px;
}

/******
Marcas estoque 
******/
.detalhes-marcas {
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}

.titulo-marcas {
  display: block;
  text-transform: uppercase;
  background: #fff;
  margin: 0 auto;
  margin-top: -30px;
  width: 12%;
  font-size: 12px;
  color: #a1a1a1;
}

.marcas {
  text-align: center;
  margin-top: 20px;
}
.marcas .item-marcas {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  max-height: 50px;
  padding: 10px;
  border: solid 1px #e5e5e5;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.marcas .item-marcas img {
  display: inline-block;
}
.marcas .item-marcas:hover {
  border: solid 1px #8a8a8a;
}

/*===========================================
	MOBILE MENU
============================================*/
#mobile-footer-menu {
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 70px;
  background-color: #18131f;
  z-index: 1100;
  overflow: hidden;
}
#mobile-footer-menu > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  height: 70px;
}
#mobile-footer-menu > ul > li {
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 1px solid #fff;
}
#mobile-footer-menu > ul > li:last-child {
  border-right: none;
}
#mobile-footer-menu > ul > li.fixed-menu-secondary-element {
  display: none;
}
@media (min-width: 600px) {
  #mobile-footer-menu > ul > li.fixed-menu-secondary-element {
    display: inline;
  }
}
#mobile-footer-menu > ul > li > a {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 19px 19px 14px 19px;
  width: 100%;
  color: #fff;
}
#mobile-footer-menu > ul > li > a i {
  font-size: 30px;
}
#mobile-footer-menu > ul > li .fixed-menu-label {
  color: #fff;
  display: block;
  font-size: 12px;
}
#mobile-footer-menu .bg-whatsapp {
  background-color: #5cb85c;
}
#mobile-footer-menu .btn-menu-mobile {
  padding: 5px 0 0 0;
  background: none;
  border: none;
}
#mobile-footer-menu .btn-menu-mobile.open {
  margin-top: -5px;
}

.menu-overlay {
  height: 100vh;
  width: 100vw;
  display: none;
  position: fixed;
  background-color: #333;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.menu-overlay.show {
  display: block;
}

.menu-top {
  float: right;
  background-color: #18131f;
}
.menu-top .menu-top_content {
  padding: 10px 0px 0px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.menu-top .menu-top_content .menu-top-content_nav {
  min-width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  margin: 0;
}
.menu-top .menu-top_content .menu-top-content_nav .border-left {
  width: 1px;
  padding: 2px;
  border-left: solid 1px #b7b7b7;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items {
  position: relative;
  line-height: 1em;
  padding-left: 10px;
  padding-right: 10px;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items .sub-menu {
  display: none;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items .sub-menu li {
  border-top: solid 1px #666;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items.lojista-out {
  background-color: #252525;
  padding: 3px;
  border-radius: 3px;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items.lojista-out a {
  color: #fff;
  text-transform: none;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items.phone-item a {
  font-size: 0.49em;
  text-transform: capitalize;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items a {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.76em;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 992px) {
  .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items a {
    font-size: 0.71em;
  }
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items:first-child {
  padding-left: 0;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items:last-child {
  padding-right: 0;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items:active a, .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items:hover a {
  font-weight: bold;
  transition: all 0s;
}
.menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items.active a {
  font-weight: bold;
  font-family: "OpenSansExtraBold";
}
@media (max-width: 768px) {
  .menu-top .menu-top_content {
    flex-flow: column;
    border-top: none;
  }
  .menu-top .menu-top_content .menu-top-content_nav {
    min-width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .menu-top .menu-top_content {
    font-size: 0.9em;
  }
}

@media (min-width: 769px) {
  .menu-top {
    display: none;
  }
  #mobile-footer-menu {
    display: none;
  }
  .menu-top-content-nav_items.phone-item {
    display: none;
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .menu-top {
    position: fixed;
    z-index: 99;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    right: -100%;
    top: 55px;
    width: 101vw;
    height: 100vh;
  }
  .menu-top.menu-show {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  .menu-top.menu-show > .menu-container {
    height: calc(100vh - 155px);
    overflow: hidden;
  }
  .menu-top .menu-top_content {
    height: 100%;
    padding-top: 0px;
    overflow: scroll;
    flex-flow: column-reverse;
    justify-content: flex-end;
  }
  .menu-top .menu-top_content .menu-top-content_nav {
    width: 100%;
    flex-flow: column;
    margin-top: 15px;
    font-size: 2em;
  }
  .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #fff;
    padding: 5px 0;
  }
  .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items:first-child {
    border-top: 1px solid #fff;
  }
  .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items.phone-item {
    border: none;
  }
  .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items a {
    color: #fff;
    display: block;
  }
  .menu-top .menu-top_content .menu-top-content_search {
    min-width: 80%;
  }
}
header .btn-menu-mobile {
  position: fixed;
  z-index: 100;
  top: 10px;
  right: 15px;
  padding: 10px 0;
}

.btn-menu-mobile {
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.btn-menu-mobile span {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+0,0.75+15,1+51,0.75+85,0+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 1%, rgba(255, 255, 255, 0.75) 15%, rgb(255, 255, 255) 51%, rgba(255, 255, 255, 0.75) 85%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 1%, rgba(255, 255, 255, 0.75) 15%, rgb(255, 255, 255) 51%, rgba(255, 255, 255, 0.75) 85%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 1%, rgba(255, 255, 255, 0.75) 15%, rgb(255, 255, 255) 51%, rgba(255, 255, 255, 0.75) 85%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#00ffffff",GradientType=0 ); /* IE6-9 */
  display: block;
  width: 30px;
  height: 4px;
  border-radius: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.btn-menu-mobile.open span:nth-child(1), .btn-menu-mobile.open span:nth-child(3) {
  transform: translate(0px, 4px) rotate(-45deg) scalex(1.3);
  margin: 0;
}
.btn-menu-mobile.open span:nth-child(2) {
  height: 0;
  margin: 0;
}
.btn-menu-mobile.open span:nth-child(3) {
  transform: translate(0px, 0px) rotate(45deg) scalex(1.3);
}

@keyframes slide-in {
  100% {
    right: 0%;
  }
}
@-webkit-keyframes slide-in {
  100% {
    right: 0%;
  }
}
@keyframes slide-out {
  0% {
    right: 0%;
  }
  100% {
    right: -100%;
  }
}
@-webkit-keyframes slide-out {
  0% {
    right: 0%;
  }
  100% {
    right: -100%;
  }
}
/*===========================================
	FOOTER
============================================*/
.horario-de-funcionamento {
  position: relative;
}
.horario-de-funcionamento .container {
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
}
.horario-de-funcionamento .nome-da-loja {
  display: inline-block;
  margin-top: 20px;
}
.horario-de-funcionamento .nome-da-loja .uma-cor {
  color: inherit;
}
.horario-de-funcionamento iframe {
  width: 100%;
}

.footer-funcionamento .nome-da-loja {
  color: white;
  margin-top: -5px;
}
.footer-funcionamento .nome-da-loja .uma-cor, .footer-funcionamento .nome-da-loja .duas-cor {
  color: inherit;
}
.footer-funcionamento .servicos-da-loja {
  margin-top: 0px;
}
.footer-funcionamento .midias {
  padding-top: 40px;
  padding-left: 132px;
}

.logo-da-empresa {
  margin-right: 20px;
  vertical-align: top;
  margin-top: 0px;
  float: left;
}

.horarios-e-mapa {
  display: flex;
  flex: auto;
  flex-direction: row;
}
.horarios-e-mapa li {
  background: #fd1233;
  color: white;
  padding: 10px;
  font-size: 16px;
}
.horarios-e-mapa li .icone i {
  color: white;
  font-size: 30px;
  margin: 5px;
}
.horarios-e-mapa lihover {
  background: #636363;
}

.horario,
.mapa {
  display: inline-block;
  text-align: left;
}

.horario {
  margin-top: 10px;
}

.icone {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.icone i {
  color: #fd1233;
  font-size: 24px;
  margin-top: 5px;
}

.ver-google-maps {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin-top: 10px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-google-maps i {
  vertical-align: top;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: -1px;
}
.ver-google-maps:hover i {
  margin-left: 10px;
}

.whatsapp-fixed {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 10000000;
}

footer {
  position: relative;
}
footer .box-footer {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #1b1b1b;
}
footer .atendimento {
  display: flex;
  flex-direction: column-reverse;
}
footer .atendimento li {
  padding: 0;
  display: block;
  margin-bottom: 30px;
  color: #e5e5e5;
  border-right: none;
}
footer .atendimento li:last-child {
  padding: 0;
}

#map {
  width: 100%;
  height: 380px;
  background: #1b1b1b;
}

ul.lojas-mapa {
  display: flex;
  flex: auto;
  flex-direction: row;
}
ul.lojas-mapa li {
  position: relative;
  flex: 1;
}
ul.lojas-mapa li a {
  display: block;
  padding: 10px 5px;
  font-size: 13px;
  color: #fff;
  background-color: #3f3f3f;
}
ul.lojas-mapa li a img {
  float: left;
  margin: 2px 10px;
}
ul.lojas-mapa li a.active, ul.lojas-mapa li a:hover {
  background-color: #1b1b1b;
}
ul.lojas-mapa li i {
  position: absolute;
  font-size: 22px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.js-showmap {
  height: 100%;
}

.maps-full {
  position: relative;
}
.maps-full > .container {
  position: absolute;
  top: -56px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.map-content {
  display: none;
}
.map-content iframe {
  width: 100%;
  height: 350px;
}
.map-content.active {
  display: block;
}

.texto-horario {
  display: inline-block;
}
.texto-horario span {
  display: block;
}

.tag-coluna {
  color: #e5e5e5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 20px;
  display: inline-block;
}

.menu-footer li {
  margin-top: 7px;
  margin-bottom: 7px;
}
.menu-footer li a {
  color: #e5e5e5;
  font-size: 14px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.menu-footer li a:hover {
  color: #fd1233;
  font-weight: 800;
}

.ms-footer li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ms-footer li i {
  color: #1b1b1b;
  background: #636363;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ms-footer li i:hover {
  background: #e5e5e5;
}

.ass-lua {
  margin-top: 70px;
  border-top: solid 1px #636363;
  padding-top: 20px;
}

.ass-footer {
  display: block;
  color: #636363;
  font-size: 12px;
}

.loading-wrapper {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-weight: bold;
  font-family: Arial;
  font-size: 14px;
  height: 2000px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999;
  display: none;
}
.loading-wrapper .loading-content {
  width: 212px;
  height: 100px;
  position: fixed;
  top: 40%;
  left: 50%;
  margin-left: -111px;
}

/*===========================================
	BOOTSTRAP
============================================*/
.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-padding .row {
  margin-left: 0;
  margin-right: 0;
}
.no-padding .row [classˆ=col-], .no-padding .row [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.alertify,
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */
}

.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  filter: alpha(opacity=0);
  opacity: 0;
}

.alertify-cover-hidden {
  display: none;
}

.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  opacity: 1;
}

.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  opacity: 0;
  display: none;
}

/* overwrite display: none; for everything except IE6-8 */
:root * > .alertify-hidden {
  display: block;
  visibility: hidden;
}

.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px;
}

.alertify-logs-hidden {
  display: none;
}

.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0;
}

.alertify-log-show {
  right: 0;
  opacity: 1;
}

.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0;
}

.alertify-dialog {
  padding: 25px;
}

.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.alertify-inner {
  text-align: center;
}

.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

.alertify-button,
.alertify-button:hover,
.alertify-button:active,
.alertify-button:visited {
  background: none;
  text-decoration: none;
  border: none;
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .alertify {
    left: 5%;
    margin: 0;
  }
}
/**
 * Default Look and Feel
 */
.alertify,
.alertify-log {
  font-family: sans-serif;
}

.alertify {
  background: #FFF;
  border: 10px solid #333; /* browsers that don't support rgba */
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
  -moz-background-clip: padding; /* Firefox 3.6 */
  background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
}

.alertify-text {
  border: 1px solid #CCC;
  padding: 10px;
  border-radius: 4px;
}

.alertify-button {
  /*
  border-radius: 4px;
  color: #FFF;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  box-shadow: inset 0 1px 0 0 rgba(255,255,255,.5);
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:    -moz-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:     -ms-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:      -o-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:         linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));*/
  border: 0;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  color: #fff;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
}

.alertify-button:hover,
.alertify-button:focus {
  outline: none;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.alertify-button:focus {
  box-shadow: 0 0 15px #2B72D5;
}

.alertify-button:active {
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus {
  background-color: #FE1A00;
  border: 1px solid #D83526;
}

.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus {
  background-color: #f78a2a;
  border: 0;
}

.alertify-log {
  background: #1F1F1F;
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  border-radius: 4px;
  color: #FFF;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-log-error {
  background: #FE1A00;
  background: rgba(254, 26, 0, 0.9);
}

.alertify-log-success {
  background: #5CB811;
  background: rgba(92, 184, 17, 0.9);
}

/*===========================================
	RESPONSIVO
============================================*/
@media screen and (max-width: 320px) {
  /* 320px */
  .slider-principal .item-slider {
    height: auto;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
}
.seta-direita,
.seta-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-de-carros-esquerda,
.seta-slider-simule-direita,
.seta-slider-simule-esquerda,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-outro-esquerda,
.seta-outro-direita {
  display: none !important;
}

.filtro li {
  border: none;
}

.botao-pos-venda {
  margin-bottom: 30px;
}
.botao-pos-venda i {
  display: none;
}
.botao-pos-venda img {
  margin-right: 5px;
}

.botao-ver-mais-carros i {
  display: none;
}

.menu-footer {
  margin-bottom: 30px;
}

.atendimento {
  display: block;
}

.ass {
  text-align: center;
  margin-top: 30px;
}

.horarios-e-mapa {
  text-align: center;
  border-left: none;
}
.horarios-e-mapa .mapa {
  margin-bottom: 20px;
}

.logo-da-empresa,
.horario,
.nome-da-loja {
  margin-right: 0;
  width: 100%;
  text-align: center;
}

footer {
  text-align: center;
}

.bg-secondary-color {
  border: 1px solid #fff;
  padding: 10px 0px;
}

.clique-menu {
  display: inline-block;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}
.clique-menu i {
  margin-left: 5px;
}

.box-menu-mobile {
  background: rgba(193, 0, 34, 0.9);
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 9000;
  left: 0;
  top: 0;
  border-top: 8px solid #fff;
  display: none;
}

.clique-fechar {
  text-transform: uppercase;
  font-weight: 900;
  text-align: right;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 20px;
}

.menu-mobile li {
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.menu-mobile li a {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 26px;
  color: #fff;
}
.menu-mobile li a:hover {
  text-decoration: underline;
}
.menu-mobile li .sub-menu {
  background: #252525;
  width: 100%;
  z-index: 9000;
  display: none;
}
.menu-mobile li .sub-menu li {
  width: 100%;
  padding-top: 20px;
  text-align: center;
  margin-bottom: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.menu-mobile li .sub-menu li a {
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: solid 1px #fff;
}
.menu-mobile li .sub-menu li:hover {
  background: #fd1233;
}
.menu-mobile li .sub-menu li:hover a {
  border-color: #fd1233;
  text-decoration: none;
}
.menu-mobile li .sub-menu li:last-child a {
  border-bottom: none;
}
.menu-mobile li.btn-whatsapp a {
  color: #fff;
  font-size: 14px;
  background-color: #5cb85c;
  padding: 10px 25px;
  border-radius: 4px;
}

.box-contato .sobre-pagina {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -200px;
}

.sobre-pagina {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -200px;
}

.menor-ativo {
  margin-left: 0;
}

@media screen and (max-width: 540px) {
  .revisao-programada-banner {
    background: transparent url("../img/slider/revisao-programada-mobile.jpg") no-repeat scroll center center;
  }
  .caixa-de-resultados .item-carros {
    margin: 20px auto;
    display: block;
  }
  .caixa-de-resultados .item-carros .item-interno-carros {
    display: block;
  }
  .items-do-filtro {
    display: unset;
  }
}
@media screen and (max-width: 767px) {
  .slider-principal .item-slider {
    height: auto;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
  header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
  }
  body {
    padding-top: 58px;
  }
  .footer-funcionamento .midias {
    padding: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .item-galeria {
    height: 230px;
  }
  .custom-modal {
    padding-left: 25px;
    padding-right: 25px;
  }
  .novo-modal {
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
  .logo-mobile {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .largura-logo-mobile {
    width: 200px;
    margin: 0 auto;
    margin-top: 10px;
  }
  .footer-funcionamento .midias {
    padding: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .slider-ofertas .item-carros .confira-oferta {
    padding: 6px 45px;
  }
  .slider-ofertas .item-carros .pre-preco {
    font-size: 12px;
    font-weight: 400;
  }
  .slider-ofertas .item-carros .de {
    font-size: 12px;
    font-weight: 600;
  }
  .slider-ofertas .item-carros .por {
    font-size: 18px;
  }
  .slider-ofertas .item-carros .pos-preco {
    font-size: 12px;
    font-weight: 600;
  }
  .slider-ofertas .item-carros .extras {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) {
  .slider-principal.--mobile {
    display: none;
  }
  .slider-principal.--desktop {
    display: block;
  }
  .slider-principal .item-slider {
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
  .filtro li {
    border-right: solid 1px #7e7e7e;
  }
  .botao-pos-venda {
    margin-bottom: 30px;
  }
  .botao-pos-venda i {
    display: block;
  }
  .botao-pos-venda img {
    margin-right: 25px;
  }
  .botao-ver-mais-carros i {
    display: inline-block;
  }
  .menu-footer {
    margin-bottom: 0px;
  }
  footer {
    text-align: left;
  }
  .atendimento {
    display: inline-block;
  }
  .ass {
    text-align: right;
    margin-top: 0px;
  }
  .horarios-e-mapa {
    text-align: right;
    border-left: solid 1px #e3e3e3;
  }
  .horarios-e-mapa .mapa {
    margin-bottom: 0px;
    text-align: left;
  }
  .logo-da-empresa {
    margin-right: 30px;
    width: auto;
    text-align: left;
  }
  .horario,
  .nome-da-loja {
    width: auto;
    text-align: left;
  }
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda {
    display: none !important;
  }
  .menor-ativo {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda,
  .seta-outro-esquerda,
  .seta-outro-direita {
    display: inline-block !important;
  }
  .seta-slider-de-carros-direita {
    right: 1%;
  }
  .seta-slider-de-carros-esquerda {
    left: 1%;
  }
  .box-contato .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: 0px;
  }
  .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 1400px) {
  .seta-slider-de-carros-direita {
    right: -5%;
  }
  .seta-slider-de-carros-esquerda {
    left: -5%;
  }
}
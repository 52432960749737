#header{
    background-color: $bg;
    color: white;
    padding: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100%;

    .scrollable{
        height: 90%;
        overflow: auto;
        position: absolute;
        width: 90%;
    }

    .menu{
        top: 56px;
        width: 750px;
        min-height: 100vh;
        position: absolute;
        background: $bg;
        padding-left: 30px;
        padding-right: 40px;
        padding-top: 30px;
        z-index: 1001;
        left: -100%;
        transition: all 0.3s linear;
        animation: slideleft 0.5s forwards;
        &.show{
            animation: slideright 0.5s forwards;
        }

        .menu-desktop{
            width: 100%;
            li{
               display: block;
               font-size: 16px;
               padding-bottom: 10px;
               padding-left: 0;
               border-bottom: solid 2px white;
               margin-bottom: 15px;
               text-decoration: none;

               a{
                   margin-left: 10px;
                   display: block;

                   &:first-child{
                       margin-left: 0;
                   }

                   &:visited, &:link{
                       color: $branco;
                   }
               }

               &.midias a{
                    display: inline-block;
               }

               .submenu{
                   display: none;
                   padding-left: 30px;
               }


                &.open-menu.open{
                    border-bottom: none;
                    margin-bottom: 0;

                    a{
                        padding-bottom: 15px;
                        border-bottom: solid 2px white;
                        margin-bottom: 30px;
                    }
                    .submenu{
                        display: block;

                        a{
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom: none;
                        }
                    }
                }




            }
        }
    }

    .container-fluid{
        padding-top: 5px;
        padding-bottom: 6px;
    }

    .btn-menu{
        cursor: pointer;
        margin-top: 7px;
        min-height: 38px;
        button{
            background: none;
            border: none;
            outline: none;
            float: left;

            span{
                display: block;
                background-color: white;
                width: 40px;
                margin-bottom: 10px;
                height: 2px;
                border-radius: 5px;
                transition: all 0.1s linear;

            }

            &.open{
                span:first-child{
                    transform: translate(5px, 12px) rotate(-45deg) scalex(0.8);
                }
                span:nth-child(2){
                    height: 0;
                    margin: 0
                }
                span:last-child{
                    transform: translate(5px, 0px) rotate(45deg) scalex(0.8);
                    margin: 0;
                }
            }

            &:focus{
                outline: none;
            }
        }
        .menu-text{
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            padding-left: 55px;
        }
    }

    .contato{
        margin: 0;
        padding-top: 5px;
        position: absolute;
        right: 15px;

        a{
            color: black;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 8px 20px;
            border-radius: 10px;
            display: inline-block;
        }
        i{
            position: relative;
            font-size: 18px;
            top: 2px;
            right: 5px;
        }
        .btn-phone{
            background-color: #00c8fa;
            margin-left: 15px;
        }
        .btn-whats{
            background-color: #63bf00;
            margin-left: 15px;
        }

        .btn-cidade{
            background-color: #00c8fa;
        }
    }

    .local-info{
        position: absolute;
        right: -10px;
        top: 56px;

        .bg-local{
            background-color: #00c8fa;
            padding: 8px 30px 8px 25px;
            transform: skewX(20deg);

            form{
                transform: skewX(-20deg);
                color: black;

                label{
                    font-size: 14px;
                }

                select{
                    background: none;
                    box-shadow: none;
                    border: none;
                    font-weight: 600;
                    margin-top: -5px;
                    color: black;
                }
            }
        }

    }
}

#box-phone {
    h5{
        font-size: 18px;
        font-weight: 600;
        color: $preto;
        margin: 20px 0;
        text-transform: uppercase;
    }
    .btn-phone{
        background-color: $main-color;
        color: $branco;
        padding: 5px 25px;
        margin-bottom: 10px;
        transform: skewX(-20deg);
        border-radius: none;

        i, strong{
            display: inline-block;
            transform: skewX(20deg);
        }
    }
}
#box-whats {
    h5{
        font-size: 18px;
        font-weight: 600;
        color: $preto;
        margin: 20px 0;
        text-transform: uppercase;
    }
    .btn-whats{
        background-color: #63bf00;
        color: $branco;
        padding: 5px 25px;
        margin-bottom: 10px;
        transform: skewX(-20deg);
        border-radius: none;

        i, strong{
            display: inline-block;
            transform: skewX(20deg);
        }
    }
}



@media (max-width: 992px){
    #header .menu{

        width: 550px;
    }

    #header .contato {
        padding-top: 5px;
        a{
            padding: 10px 20px;
        }
    }
}

@media (max-width: 767px){
    #header{

        .container-fluid{
            padding-bottom: 12px;
        }

        .local-info{
            top: 58px;
            width: 275px;

            form select{
                width: 145px;
                display: inline-block;
            }
        }
    }

}

@keyframes slideright {
    100% { left: 0%; }
}
@keyframes slideleft {
    0% { left: -100%; }
}

// Header Primário

header{
	background: #fff;
	box-shadow: 0px 1px 3.92px 0.08px rgba(0, 0, 0, 0.3);
}

.logo{ font-size: inherit; }

.atendimento{
	display: inline-block;
	li{
		display: inline-block;
		padding-right: 25px;
		padding-left: 25px;
		border-right: solid 1px $cinza;
		.icone-atendiento{
			display: inline-block;
			font-size: 20px;
			margin-right: 5px;

			vertical-align: sub;
			color: $cinza;
		}
		.numero-atendimento{
			display: inline-block;
			span{
				display: block;
				font-size: 14px;
				color: $cinza;
			}
			strong{
				display: block;
				font-size: 14px;
				font-weight: 800;
				color: $cinza;
			}
		}
		&:last-child{
			padding-right: 0;
			border-right: none;
		}

		&.btn-whatsapp{
			background-color: #5cb85c;
			padding: 5px 25px;
			border-radius: 4px;


			i, span, strong{
				color: $branco;
			}
			a{
				color: $branco;
				font-size: 14px;
			}
		}
	}

	&.footer li{
		.icone-atendiento{
			color: $branco;
		}
		.numero-atendimento{
			span, strong{
				color: $branco;
			}
		}
	}
}


// Sub Header

.sub-header{
	padding: 20px 0px;
	background: $corMain;
	color: $branco;
}

.nome-duas-cores{ margin-bottom: 0; }
.uma-cor, .duas-cor{
	color: $preto;
	font-size: 32px;
	font-style: italic;
}

.servicos-da-loja{ margin-top: -12px; }

.uma-cor{
	font-weight: 700;
}
.duas-cor{
	font-weight: normal;
}

.menu{
	li{
		display: inline-block;
		padding-right: 25px;
		position: relative;
		padding: 10px;
		a{
			text-transform: uppercase;
			font-weight: 600;
			&:hover{
				text-decoration: underline;
			}
		}

		.sub-menu{
			position: absolute;
			top: 32px; left: 0;
			background: $corMain;
			z-index: 200;
			display: none;
			li{
				width: 250px;
				margin-right: 0;
				@include transition(0.3s);
				padding-left: 30px;
				padding-bottom: 0;
				padding-right: 0;
				a{
					display: block;
					color: $branco;
					padding-bottom: 20px;
					padding-top: 20px;
					border-bottom: solid 1px $branco;
				}

				&:hover{
					background: #252525;
					a{ border-color: #252525; text-decoration: none;}
				}

				&:last-child{
					a{
						border-bottom: none;
					}
				}
			}
		}

		&:hover{
			.sub-menu{ display: block; }
		}
	}
}

.icones-leads{
	text-align: right;
	margin-top: 35px;
	span{
		margin-left: 30px;
		margin-right: 30px;
		cursor: pointer;
		i{
			font-size: 25px;
		}
		.fa-mobile{ font-size: 30px;  vertical-align: top;}
	}
}

@media (min-width: 768px){
    header{
      padding-bottom:20px;
      padding-top:20px;
      #faixa-cima{
        display:none;
      }

    }
}

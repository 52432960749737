// Cores
$branco: #fff; /* Cor padrão branca*/
$preto: #1b1b1b; /* Cor Padrão Preta - Tom de Preto com base no Layout */

$body: #edede3;
$corMain: #fd1233; /* Cor primária do site */
$main-color: #fd1233;
$main-color-light: darken($main-color, 20%);
$secondary-color: #1b1b1b;
$cta-color: #252525;
$cta-text-color: white;
$corSeta: #fff; /* Cor da seta dos Sliders */
$bgBotaoConfiraOferta: #252525; /* BG que envolve as setas */
$corTextoBotaoConferirOferta: #fff; /* Cor do Texto do botão de oferta e oferta menores */
$cinza: #636363;
$txtSecondary: #8b3f29;
$bg: #18131f;
// Fonts
$fontmain: 'Open Sans', sans-serif; /* Fonte padrão de todo o Site */


// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}